<template>
  <ItemWrapper>
    <v-row>
      <v-col cols="12">
        <AppCard :title="$t('Create Invoice')" :prev-route="prevRoute">
          <ValidationObserver v-slot="{ handleSubmit }">
            <v-form autocomplete="off" @submit.prevent="handleSubmit(submitForm)">
              <v-container py-0>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="4">
                    <SearchSelectClient v-model="client_id" rules="required" />
                  </v-col>

                  <v-col cols="12" sm="3" lg="4">
                    <DatePickerWithValidation
                      v-model="billingdate"
                      label="Billingdate"
                      name="billingdate"
                      rules="required"
                      placeholder="Enter Billingdate"
                    />
                  </v-col>

                  <v-col cols="12" sm="3" lg="2">
                    <VTextFieldWithValidation
                      v-model="paymenttime"
                      label="Paymenttime"
                      name="paymenttime"
                      rules="required|integer|min_value:0"
                      placeholder="Enter Paymenttime"
                    />
                  </v-col>

                  <v-col cols="12" sm="4" lg="2">
                    <VTextFieldWithValidation
                      v-model="skonto"
                      label="Skonto"
                      name="skonto"
                      rules="integer|min_value:0"
                      placeholder="Enter Skonto"
                    />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <VSelectWithValidation
                      v-model="tenant_id"
                      rules="required"
                      label="Tenant"
                      :items="tenantsArray"
                      :disabled="pending.getInvoicePresets"
                      menu-props="offset-y"
                      placeholder="Select Tenant"
                    />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <VSelectWithValidation
                      v-model="invoicerange_id"
                      rules="required"
                      label="Invoicerange"
                      :items="invoiceRangesArray"
                      :disabled="pending.getInvoicePresets"
                      menu-props="offset-y"
                      placeholder="Select Invoicerange"
                    />
                  </v-col>

                  <v-col cols="12" sm="4" lg="2">
                    <VTextFieldWithValidation
                      v-model="discount"
                      label="Discount"
                      name="discount"
                      rules="integer|min_value:0"
                      placeholder="Enter Discount"
                    />
                  </v-col>

                  <v-col cols="12" sm="4" lg="2">
                    <VSelectWithValidation
                      v-model="discount_type"
                      label="Discount Type"
                      :items="discountTypes"
                      menu-props="offset-y"
                      placeholder="Select Discount Type"
                    />
                  </v-col>

                  <v-col cols="12" sm="4" lg="2">
                    <VSelectWithValidation
                      v-model="shipping_type"
                      label="Shipping Type"
                      :items="shippingTypes"
                      menu-props="offset-y"
                      placeholder="Select Shipping Type"
                    />
                  </v-col>

                  <v-col v-if="shipping_type && shipping_type !== 'hide'" cols="12" sm="3" lg="4">
                    <DatePickerWithValidation
                      v-model="shippingdate"
                      :label="`${shipping_type} Date`"
                      name="shippingdate"
                      rules="required"
                      :placeholder="`Enter ${shipping_type} Date`"
                    />
                  </v-col>

                  <v-col
                    v-if="shipping_type && shipping_type !== 'hide' && shipping_type.includes('period')"
                    cols="12"
                    sm="3"
                    lg="4"
                  >
                    <DatePickerWithValidation
                      v-model="shippingenddate"
                      :label="`${shipping_type} End Date`"
                      name="shippingenddate"
                      rules="required"
                      :placeholder="`Enter ${shipping_type} End Date`"
                    />
                  </v-col>

                  <v-col cols="12" px-0>
                    <v-divider />
                  </v-col>

                  <v-col cols="12">
                    <h3 class="text-h5 mt-0">Positions</h3>

                    <draggable v-model="allProducts" handle=".handle">
                      <template v-for="(invoice_product, key) in invoice_products">
                        <InvoiceProductRow
                          :key="key"
                          :index="key"
                          :invoice-product="invoice_product"
                          :length="invoice_products.length"
                          @removeInvoiceProduct="removeInvoiceProduct"
                        />
                      </template>
                    </draggable>

                    <v-btn color="success" class="mt-4" @click="addInvoiceProduct">Add position</v-btn>
                  </v-col>

                  <v-col cols="12" px-0>
                    <v-divider />
                  </v-col>

                  <v-col cols="12">
                    <VTextareaWithValidation
                      v-model="extratext"
                      label="Invoice Text"
                      name="invoicetext"
                      placeholder="Enter Invoice Text"
                    />
                  </v-col>

                  <v-col cols="12" class="text-right">
                    <v-btn
                      type="submit"
                      class="mx-0 font-weight-light"
                      color="success"
                      :disabled="pending.createInvoice"
                    >
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </AppCard>
      </v-col>
    </v-row>
  </ItemWrapper>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref, watch } from '@vue/composition-api'
import { mapMultiRowFields } from 'vuex-map-fields'
import { mapFields } from 'vuex-composition-map-fields'

import { parseObjectToArray } from '@/helpers'
import { discountTypes, shippingTypes } from '@/config'
import useDirectRedirectToItem from '@/composables/useDirectRedirectToItem'

import draggable from 'vuedraggable'

import AppCard from '@/components/UI/AppCard'
import DatePickerWithValidation from '@/components/inputs/DatePickerWithValidation'
import InvoiceProductRow from '@/components/Invoices/InvoiceProductRow'
import ItemWrapper from '@/layouts/ItemWrapper'
import SearchSelectClient from '@/components/Clients/SearchSelectClient'
import VSelectWithValidation from '@/components/inputs/VSelectWithValidation'
import VTextareaWithValidation from '@/components/inputs/VTextareaWithValidation'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation'

export default defineComponent({
  name: 'CreateInvoice',
  components: {
    draggable,
    AppCard,
    DatePickerWithValidation,
    InvoiceProductRow,
    ItemWrapper,
    SearchSelectClient,
    VSelectWithValidation,
    VTextareaWithValidation,
    VTextFieldWithValidation,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router, $moment } }) {
    const { redirectDirect, setForceRedirect } = useDirectRedirectToItem()
    const prevRoute = ref('/Invoices')

    // store
    const pending = computed(_ => $store.state.invoice.pending)
    const invoicePresets = computed(_ => $store.state.invoice.invoicePresets)
    const invoice = computed(_ => $store.state.invoice.invoice)
    const errors = computed(_ => $store.state.invoice.errors)
    const getInvoicePresets = _ => $store.dispatch('invoice/getInvoicePresets')
    const createInvoice = _ => $store.dispatch('invoice/createInvoice')
    const clearInvoice = _ => $store.dispatch('invoice/clearInvoice')
    const addInvoiceProduct = _ => $store.dispatch('invoice/addInvoiceProduct')
    const removeInvoiceProduct = productIndex => $store.dispatch('invoice/removeInvoiceProduct', productIndex)
    const updateAllInvoiceProducts = products => $store.commit('invoice/UPDATE_ALL_INVOICE_PRODUCTS', products)
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    const invoiceMapField = mapFields('invoice', [
      'invoice.client_id',
      'invoice.discount_type',
      'invoice.discount',
      'invoice.billingdate',
      'invoice.paymenttime',
      'invoice.skonto',
      'invoice.shipping_type',
      'invoice.shippingdate',
      'invoice.shippingenddate',
      'invoice.tenant_id',
      'invoice.invoicerange_id',
      'invoice.extratext',
    ])

    const invoiceRangesArray = computed(_ => parseObjectToArray(invoicePresets.value.invoiceranges))
    const tenantsArray = computed(_ => parseObjectToArray(invoicePresets.value.tenants))

    getInvoicePresets()
    invoiceMapField.billingdate.value = $moment().format('YYYY-MM-DD')

    watch(invoicePresets, _ => {
      invoiceMapField.invoicerange_id.value = parseInt(Object.keys(invoicePresets.value.invoiceranges)[0])
      invoiceMapField.tenant_id.value = parseInt(Object.keys(invoicePresets.value.tenants)[0])
    })

    const allProducts = computed({
      set: val => updateAllInvoiceProducts(val),
      get: _ => invoice.value.invoice_products,
    })

    const submitForm = _ => {
      if (pending.value.createInvoice) {
        return
      }
      if (redirectDirect.value) {
        setForceRedirect(true)
      }

      createInvoice().then(({ invoice, errors }) => {
        if (errors) {
          setForceRedirect(false)
          addToast({
            msg: 'Erstellung fehlgeschlagen',
            type: 'error',
          })
          return
        }

        addToast({
          msg: 'Rechnung erfolgreich erstellt',
          type: 'success',
        })

        if (redirectDirect.value) {
          $router.push({
            name: 'invoices-item',
            params: { id: invoice.id },
          })
        } else {
          $router.push({ path: '/Invoices' })
        }
      })
    }

    onUnmounted(_ => {
      clearInvoice()
    })

    return {
      prevRoute,
      invoiceRangesArray,
      tenantsArray,
      pending,
      errors,
      discountTypes,
      shippingTypes,
      allProducts,
      ...invoiceMapField,
      addInvoiceProduct,
      removeInvoiceProduct,
      submitForm,
    }
  },
  computed: {
    ...mapMultiRowFields('invoice', ['invoice.invoice_products']),
  },
}) //
</script>
